import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7cf34a45 = () => interopDefault(import('../pages/my-account.vue' /* webpackChunkName: "pages/my-account" */))
const _e5dc596c = () => interopDefault(import('../pages/prefs/index.vue' /* webpackChunkName: "pages/prefs/index" */))
const _2846fee6 = () => interopDefault(import('../pages/results.vue' /* webpackChunkName: "pages/results" */))
const _4c53d47b = () => interopDefault(import('../pages/signIn.vue' /* webpackChunkName: "pages/signIn" */))
const _e463f962 = () => interopDefault(import('../pages/username.vue' /* webpackChunkName: "pages/username" */))
const _d8c859ae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/my-account",
    component: _7cf34a45,
    name: "my-account"
  }, {
    path: "/prefs",
    component: _e5dc596c,
    name: "prefs"
  }, {
    path: "/results",
    component: _2846fee6,
    name: "results"
  }, {
    path: "/signIn",
    component: _4c53d47b,
    name: "signIn"
  }, {
    path: "/username",
    component: _e463f962,
    name: "username"
  }, {
    path: "/",
    component: _d8c859ae,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
